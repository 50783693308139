<template>
    <div>
        <div class="box" v-loading.fullscreen.lock="fullscreenLoading">
            <Title title="使用记录" />
            <div class="ct_box">
                <div class="search-status_box flex">
                    <div class="search_box flex">
                        <div class="ai_select flex">
                            <img src="@/assets/user/setMenu/search.png" alt="" class="search_icon" />
                            <el-select clearable @change="classChane" v-model="title" placeholder="请选择要搜索的类别">
                                <el-option v-if="item.id != 10 && item.id != 21 && item.id != 22"
                                    v-for="item in search_list" :label="item.name" :key="item.id" :value="item.id">
                                </el-option>
                            </el-select>
                            <!-- <button class="search_but" @click="getList">搜索</button> -->
                        </div>
                    </div>
                </div>
                <table class="table2">
                    <thead>
                        <tr>
                            <td>ID</td>
                            <td>类别</td>
                            <td>输入内容</td>
                            <td>AI生成内容</td>
                            <td>token</td>
                            <td>消费金额</td>
                            <td>使用日期</td>
                            <td style="width: 10rem;">操作</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in tableData" :key="item.id">
                            <td>{{ (page - 1) * limit + 1 + index }}</td>
                            <!--   -->
                            <td>{{ search_list[parseInt(item.chat_model) - 1].name }}</td>

                            <td style="color: #108CDD;cursor: pointer;" @click="wordOrderShow(0, item)">
                                {{ item.input_content == '' ? '-' : '查看' }}
                            </td>

                            <td style="color: #108CDD;cursor: pointer;" @click="wordOrderShow(1, item)">
                                查看
                            </td>
                            <td class="money">{{ item.total_token }}</td>
                            <td class="money">-￥{{ item.money }}</td>
                            <td>
                                {{ item.timer }}
                            </td>
                            <td class="operation4">
                                <p @click="copyCont(item.output_content)">一键复制</p>
                                <p @click="deleteFn(index)">删除</p>
                            </td>

                        </tr>
                    </tbody>
                </table>
                <!-- 分页器 -->
                <pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page" />
            </div>
        </div>
        <el-dialog title="输入内容/生成内容" :append-to-body="true" :visible.sync="WorkOrder" :show-close="false">
            <p v-if="contentText.indexOf('jpg') == -1 && contentText.indexOf('png') == -1" v-text="contentText"></p>
            <img style="display: block; margin: 0 auto;" v-else :src="contentText" />
            <div class="but_box flex">
                <button @click="copyCont(contentText)"
                    v-if="contentText.indexOf('jpg') == -1 && contentText.indexOf('png') == -1">一键复制</button>
                <button @click="$downloadByBlob(contentText)" v-else>下载图片</button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Title from '../../components/UserInfoTitle.vue'
import { mapState } from 'vuex'
import { timeCut } from '../../util/timeCut'
export default {
    components: {
        Title,
    },
    computed: {
        ...mapState(['activeTitle'])
    },
    mounted() {
        this.$store.commit('childTab', 'AI记录')
        let searchName_list = ['企业简介', '个人简介', '产品简介', '记叙文', '议论文', '祝福语', '广告语', '短视频脚本', '纪录片脚本', '错别字修改', '标题提炼', '文稿润色', '文稿改写', 'AI作图(普通版)', 'AI作图(高级版)', 'AI成诗', 'AI律师函', 'AI版权侵权告知书', 'AI起诉书', '黑白图像上色', '图像风格转换', '人物动漫化', 'AI抠图', '背景生成', 'AI视频生成','营销软文','说明书','商业计划书','小说/歌词','产品活动策划方案','发言稿','演讲稿','小红书文案（基础版）','小红书文案（电商版）','PPT内容框架']
        searchName_list.forEach((item, index) => {
            this.search_list.push({
                id: index + 1,
                name: item
            })
        })
        console.log(this.search_list);
        this.getList()

    },
    data() {
        return {
            title: '',
            statusUl: ['全部', '已处理', '未处理',],
            status: {
                id: '',
                title: '全部'
            },
            search_list: [],
            tableData: [],
            total_page: 0, //分页总页数
            page: 1, //当前页数
            limit: 20,
            count: 0,
            fullscreenLoading: false, //loding
            WorkOrder: false,
            contentText: '',
        }
    },
    methods: {
        pageChange(pageVal) {
            this.page = pageVal
            this.getList()
        },
        statusShow(item) {
            if (item == '全部') {
                this.status = {
                    id: '',
                    title: '全部'
                }

            } else if (item == '未处理') {
                this.status = {
                    id: 0,
                    title: '未处理'
                }
            } else {
                this.status = {
                    id: 1,
                    title: '已处理'
                }

            }
            this.getList()
        },
        wordOrderShow(type, item) {
            if (type == 0) {
                this.contentText = item.input_content
            } else {
                this.contentText = item.output_content
            }
            this.WorkOrder = true
        },
        classChane(index) {
            console.log(index);
            this.status = this.search_list[index - 1]
            this.getList()
        },
        copy() {
            //参数是复制的内容
            //.then()中的函数是复制成功和复制失败的两个回调函数
            this.$copyText(this.contentText).then(
                (e) => {
                    return this.$message({
                        message: '复制成功',
                        type: 'success',
                    })
                },
                (err) => {
                    return this.$message({
                        message: '复制失败',
                        type: 'warning',
                    })
                }
            )

        },
        copyCont(text) {
            //参数是复制的内容
            //.then()中的函数是复制成功和复制失败的两个回调函数
            this.$copyText(text).then(
                (e) => {
                    return this.$message({
                        message: '复制成功',
                        type: 'success',
                    })
                },
                (err) => {
                    return this.$message({
                        message: '复制失败',
                        type: 'warning',
                    })
                }
            )

        },
        getList() {
            this.fullscreenLoading = true
            let data = {}
            if (this.status.id != '') {
                data.title = this.status.id
            }
            data.mobile = this.$user_info.mobile
            data.page_num = this.page
            data.limit = this.limit
            this.curlPost('/file/create_history', data).then(res => {
                console.log(res);
                if (res.data.code) {
                    this.total_page = res.data.totle_page
                    this.count = res.data.count
                    this.tableData = res.data.data

                    if (this.tableData.length != 0) {
                        this.tableData.forEach((item, index) => {
                            // item.total_token = item.total_token
                            this.$set(item, 'timer', timeCut(item.add_time * 1000))
                            // this.tableData[index].total_token = this.tableData[index].total_token * 0.0006
                            this.$set(item, 'chat_class',)

                        })
                    }

                }
                this.fullscreenLoading = false
            })
        },
        ClassNum(val) {
            let resultArr = this.gatewayList.filter((item) => {
                return item.title === val
            });
            console.log(resultArr);
            this.resource = resultArr[0]
        },
        deleteFn(index) {
            this.$confirm('确认删除吗?该操作不可撤回', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.curlPost('/file/delete_order', {
                    delete_id: this.tableData[index].id,
                    mobile: this.$user_info.mobile
                }).then(res => {
                    console.log(res);
                    if (res.data.code) {
                        this.tableData.splice(index, 1)
                        this.$message({
                            type: 'success',
                            message: '已删除'
                        });
                    }
                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        }
    },
}
</script>

<style lang="scss" scoped>
@import '../../scss/MediaOrder';

::v-deep .el-dialog .el-dialog__body {
    padding: 1rem 2rem 6rem;
    box-sizing: border-box;
}
</style>